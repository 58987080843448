/* https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500;600&display=swap */
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librefranklin/v7/jizDREVItHgc8qDIbSTKq4XkRiUR2zcZiVbJsNo.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librefranklin/v7/jizDREVItHgc8qDIbSTKq4XkRiUf2zcZiVbJ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librefranklin/v7/jizDREVItHgc8qDIbSTKq4XkRiUR2zcZiVbJsNo.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librefranklin/v7/jizDREVItHgc8qDIbSTKq4XkRiUf2zcZiVbJ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librefranklin/v7/jizDREVItHgc8qDIbSTKq4XkRiUR2zcZiVbJsNo.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/librefranklin/v7/jizDREVItHgc8qDIbSTKq4XkRiUf2zcZiVbJ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html {
  font-size: 100%;
}

body {
  background: var(--bg-color, "#F2F4FA");
  color: var(--body-text-color, "#262c31");
  font-weight: var(--body-text-font-weight, "400");
  line-height: var(--body-text-line-height, "1.5");
  font-family: "Libre Franklin", sans-serif;
}

a:not(:disabled) {
  color: var(--link-color, #003d98);
}

a:disabled {
  color: var(--disabled-color, #686c6f);
}

a:active:not(:disabled) {
  color: var(--link-color, #003d98);
}

a:focus:not(:disabled) {
  color: var(--link-color, #003d98);
}

a:hover:not(:disabled) {
  color: var(--link-color, #003d98);
}

a:visited:not(:disabled) {
  color: var(--link-color, #003d98);
}

p {
  margin-bottom: 1rem;
}

/**
 * @see https://type-scale.com/?size=16&scale=1.125&text=A%20Visual%20Type%20Scale&font=Libre%20Franklin&fontweight=600&bodyfont=body_font_default&bodyfontweight=400&lineheight=1.75&backgroundcolor=%23ffffff&fontcolor=%23000000&preview=false
 */

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  line-height: 1.3;
}

h1 a:not(:disabled),
h1 a:visited:not(:disabled),
h1 a:hover:not(:disabled),
h1 a:focus:not(:disabled),
h1 a:active:not(:disabled) {
  color: initial;
}

h1 {
  margin-top: 0;
  font-size: 1.802rem;
  font-weight: 600;
}

h2 a:not(:disabled),
h2 a:visited:not(:disabled),
h2 a:hover:not(:disabled),
h2 a:focus:not(:disabled),
h2 a:active:not(:disabled) {
  color: initial;
}

h2 {
  font-size: 1.602rem;
  font-weight: 600;
}

h3 a:not(:disabled),
h3 a:visited:not(:disabled),
h3 a:hover:not(:disabled),
h3 a:focus:not(:disabled),
h3 a:active:not(:disabled) {
  color: initial;
}

h3 {
  font-size: 1.424rem;
  font-weight: 500;
}

h4 a:not(:disabled),
h4 a:visited:not(:disabled),
h4 a:hover:not(:disabled),
h4 a:focus:not(:disabled),
h4 a:active:not(:disabled) {
  color: initial;
}

h4 {
  font-size: 1.266rem;
  font-weight: 500;
}

h5 a:not(:disabled),
h5 a:visited:not(:disabled),
h5 a:hover:not(:disabled),
h5 a:focus:not(:disabled),
h5 a:active:not(:disabled) {
  color: initial;
}

h5 {
  font-size: 1.125rem;
  font-weight: 500;
}

small,
.text_small {
  font-size: 0.889rem;
}

strong {
  font-weight: 600;
}

/**
 * Skip link for accessibility
 * @see https://web.dev/headings-and-landmarks/#bypass-repetitive-content-with-skip-links
 */

.skip-link {
  position: absolute;
  top: -44px;
  left: 0;
  background: #000;
  color: var(--link-color, #003d98);
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

.sidebar {
  width: 208px;
  animation: expandSidebar 0.2s ease forwards;
}

.sidebar.collapsed {
  animation: collapseSidebar 0.2s ease forwards;
}

@keyframes expandSidebar {
  from {
    width: 56px;
    background-color: #3c4246;
  }
  to {
    width: 208px;
    background-color: #f1f1f1;
  }
}

@keyframes collapseSidebar {
  0% {
    width: 208px;
    background-color: #f1f1f1;
  }
  100% {
    width: 58px;
    background-color: #3c4246;
  }
}

.toast-wrapper {
  padding: 0px !important;
}

.toast-wrapper .Toastify__toast-body {
  padding: 0px !important;
}
